<template>
  <div v-show="showIfArray.some((element) => store.state.resultData.includes(element)) || showAlways" :class="['col-sm-6', 'mb-4', lgClass]">
    <div class="portfolio-item">
      <a class="portfolio-link" :href="url" target="_blank">
        <div class="portfolio-hover"></div>
        <slot />
      </a>
      <div class="portfolio-caption">
        <div class="portfolio-caption-heading">{{ title }}</div>
        <div class="portfolio-caption-subheading text-muted">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, computed } from "vue";

// Store wird injiziert
const store = inject("store");

const props = defineProps({
  imageSrc: String,
  title: String,
  text: String,
  showIfArray: { type: Array, default: () => [] },
  showAlways: Boolean,
  cols: Number,
  url: String,
});

const lgClass = computed(() => `col-lg-${props.cols}`);
</script>
