<template>
  <a
    :href="url"
    target="_blank"
    v-show="showIfArray.some((element) => store.state.resultData.includes(element)) || showAlways"
    class="list-group-item list-group-item-action flex-column align-items-start"
  >
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1"><i class="fa-solid fa-up-right-from-square"></i> {{ title }}</h5>
      <small>{{ type }}</small>
    </div>
    <p class="mb-1">{{ text }}</p>
  </a>
</template>

<script setup>
import { inject } from "vue";

// Store wird injiziert
const store = inject("store");

const props = defineProps({
  type: String,
  title: String,
  text: String,
  showIfArray: { type: Array, default: () => [] },
  showAlways: Boolean,
  url: String,
});
</script>

<style scoped></style>
