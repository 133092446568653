<template>
  <div class="container" style="min-height: 600px">
    <div class="row justify-content-center pt-4">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Admin Dashboard</div>
          <div class="card-body">
            <div class="d-grid gap-2">
              <button class="btn btn-lg btn-info" type="button" @click="downloadButtonClicked" :disabled="isLoading">
                <span v-if="!isLoading">Download CSV</span>
                <span v-else> <i class="fas fa-spinner fa-spin"></i> Loading... </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const isLoading = ref(false);

const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  const headers = Object.keys(array[0]);
  const csvRows = [];

  // Header row
  csvRows.push(headers.join(";"));

  // Data rows
  array.forEach((item) => {
    const values = headers.map((header) => {
      const value = item[header];
      return typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value;
    });
    csvRows.push(values.join(";"));
  });

  return csvRows.join("\n");
};

const downloadCSV = (csvContent) => {
  const csv = convertToCSV(csvContent);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.setAttribute("href", URL.createObjectURL(blob));
  link.setAttribute("download", "survey_results.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadButtonClicked = () => {
  isLoading.value = true;
  axios
    .get('/api/survey-results')
    .then((response) => {
      if (response.status === 200) {
        downloadCSV(response.data);
      } else {
        console.error("Error in response.status: " + response.status);
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
