export const surveyJson = {
  pageNextText: "Weiter",
  pagePrevText: "Zurück",
  completeText: "Absenden",
  //firstPageIsStarted: true,
  //startSurveyText: "Assessment beginnen",
  showQuestionNumbers: "off",
  showProgressBar: "belowHeader",
  progressBarType: "pages",
  progressBarShowPageNumbers: false,
  progressBarShowPageTitles: false,
  width: "100%",
  questionTitlePattern: "numTitle",
  pages: [
    /*
    {
      elements: [
        {
          type: "html",
          html:
            "<h3>Stellen Sie Ihr Unternehmen auf die Probe!\n</h3>" +
            "<br>" +
            "<p>Künstliche Intelligenz bietet eine Vielzahl von Möglichkeiten, Prozesse zu optimieren und so die Effizienz zu steigern. Sie schafft jedoch auch Freiräume für wichtigere und kreativere Aufgaben, die dem Unternehmen zusätzliche Mehrwerte generieren.</p>" +
            "<br>" +
            "<p>Dieser Selbsttest zielt darauf ab, Ihnen einen Überblick über die Voraussetzungen und die Implementierung von KI-Technologien in Ihrem Unternehmen zu verschaffen. HP möchte Sie auf Ihrer KI-Reise unterstützen und Ihnen auf Basis Ihrer Antworten individuell zugeschnittene Empfehlungen für Weiterbildungsmöglichkeiten (z. B. Literatur und Workshops) präsentieren.  Bitte nehmen Sie sich daher etwas Zeit, die Fragen sorgfältig zu beantworten, um ein genaues Bild davon zu erhalten, wie weit Ihr Unternehmen auf dem Weg zur Integration von KI ist und in welchen Bereichen noch Potenzial zur Weiterentwicklung besteht.</p>",
        },
      ],
    },
    */

    {
      name: "Unternehmensgröße",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "size",
          title: "Wie viele Beschäftigte sind in Ihrem Unternehmen angestellt?",
          choices: [
            { value: 1, text: "1 bis 49 Beschäftigte" },
            { value: 2, text: "50 bis 249 Beschäftigte" },
            { value: 3, text: "250 bis 499 Beschäftigte" },
            { value: 4, text: "500 bis 999 Beschäftigte" },
            { value: 5, text: "1.000 oder mehr Beschäftigte" },
          ],
        },
      ],
    },
    {
      name: "Branche",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "sector",
          title: "Welcher Branche lässt sich Ihr Unternehmen zuordnen?",
          colCount: 3,
          choices: [
            { value: 1, text: "Landwirtschaft, Forstwirtschaft und Fischerei" },
            { value: 2, text: "Abbau von Rohstoffen" },
            { value: 3, text: "Industrie / Verarbeitendes Gewerbe (ohne Automobilindustrie" },
            { value: 4, text: "Automobilindustrie" },
            { value: 5, text: "Energieversorgung" },

            { value: 6, text: "Baugewerbe" },
            { value: 7, text: "Handel" },
            { value: 8, text: "Verkehr, Transport und Logistik" },
            { value: 9, text: "Gastgewerbe" },
            { value: 10, text: "Information und Kommunikation" },

            { value: 11, text: "Finanz- und Versicherungsdienstleistungen" },
            { value: 12, text: "Grundstücks- und Wohnungswesen" },
            { value: 13, text: "Freiberufliche, wissenschaftliche und technische Dienstleistungen" },
            { value: 14, text: "Sonstigen wirtschaftlichen Dienstleistungen" },
            { value: 15, text: "Öffentliche Verwaltungen" },

            { value: 16, text: "Erziehung und Unterricht" },
            { value: 17, text: "Gesundheits- und Sozialwesen" },
            { value: 18, text: "Kunst, Unterhaltung und Erholung" },
            { value: 19, text: "Medienbranche" },
            { value: 20, text: "Erbringung von sonstigen Dienstleistungen" },
          ],
        },
      ],
    },
    {
      name: "Bereich / Tätigkeit",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "position",
          title: "Welchem Joblevel würden Sie sich zuordnen?",
          showOtherItem: true,
          otherText: "Sonstige",
          choices: [
            { value: 1, text: "Besitzende" },
            { value: 2, text: "Vorstand" },
            { value: 3, text: "Geschäftsführung / CEO" },
            { value: 4, text: "CTO / CIO / CDO" },
            { value: 5, text: "Sonstige CxO-Position" },
            { value: 6, text: "Management / Abteilungsleitung" },
            { value: 7, text: "IT-Beschäftigte" },
          ],
        },
      ],
    },
    {
      name: "Office-Hardware",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Q1",
          title: "Wie alt ist Ihre aktuelle PC- und Notebook-Flotte?",

          choices: [
            { value: 1, text: "Unsere Flotte ist bis zu einem Jahr alt" },
            { value: 2, text: "Unsere Flotte ist 2 bis 3 Jahre alt" },
            { value: 3, text: "Unsere Flotte ist 4 bis 5 Jahre alt" },
            { value: 4, text: "Unsere Flotte ist 6 bis 10 Jahre alt" },
            { value: 5, text: "Unsere Flotte ist älter als 10 Jahre" },
          ],
        },
      ],
    },
    {
      name: "Office OS",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Q2",
          title: "Welches Betriebssystem betreiben Sie aktuell mehrheitlich?",

          choices: [
            { value: 1, text: "Windows 11" },
            { value: 2, text: "Windows 10" },
            { value: 3, text: "Windows 7 oder älter" },
            { value: 4, text: "iOS" },
            { value: 5, text: "MacOS" },
            { value: 6, text: "Linux" },
          ],
        },
      ],
    },
    {
      name: "Einsatzfelder",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q3",
          title: "In welchen Einsatzbereichen könnten Sie sich die Verwendung von KI in Ihrem Unternehmen vorstellen oder setzen Sie KI bereits ein?",
          description: "Mehrfachauswahl möglich",
          showNoneItem: true,
          noneText: "Wir können uns den Einsatz von KI aktuell in keinem Bereich vorstellen",
          showOtherItem: true,
          otherText: "Wir können uns den Einsatz von KI in einem anderen Bereich vorstellen oder setzen es in einem anderen Bereich ein",
          choices: [
            { value: 1, text: "Logistik & Produktion (z. B. Prozessoptimierung, Process Mining, Predictive Maintenance)" },
            {
              value: 2,
              text: "Marketing & Kundenkommunikation (z. B. Chatbots, Verhaltensanalyse, Generative AI, zeitoptimierte Planung von Social Media Postings)",
            },
            { value: 3, text: "Data Analytics (z. B. KI-generierte Auswertungen und Berichte)" },
            {
              value: 4,
              text: "Ressourcenmanagement (z. B. Personaleinsatz, Bestandsmanagement, Energiemanagement, dynamische Preis- und Sortimentsgestaltung)",
            },
            { value: 5, text: "Finance (z. B. KI-basierte Investitionsvorschläge)" },
          ],
        },
      ],
    },
    {
      name: "KI-Offenheit Beschäftigte",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Q4",
          title: "Wie offen stehen Ihre Beschäftigen der Nutzung von KI-Tools gegenüber?",

          choices: [
            { value: 1, text: "Unsere Beschäftigten stehen der Nutzung von KI-Tools vollkommen offen gegenüber und hegen keinerlei Bedenken" },
            {
              value: 2,
              text: "Unsere Beschäftigten stehen KI-Tools positiv gegenüber, haben aber Schwierigkeiten dabei, sich konkrete Anwendungsfälle bzw. Mehrwerte vorzustellen",
            },
            {
              value: 3,
              text: "Unsere Beschäftigten haben allgemeines Interesse an KI-Tools, jedoch vereinzelt grundlegende Bedenken (Sicherheit, Datenschutz, Transparenz)",
            },
            {
              value: 4,
              text: "Unsere Beschäftigten sind derzeit nicht an der Nutzung von KI-Tools interessiert, stehen dem Thema aber prinzipiell nicht verschlossen gegenüber",
            },
            {
              value: 5,
              text: "Unsere Beschäftigten sind nicht offen für die Nutzung von KI-Tools und haben auch kein Interesse daran, dies zu ändern",
            },
          ],
        },
      ],
    },
    {
      name: "Bekanntheit KI-Tools",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q5",
          title: "Mit welchen KI-Tools sind Ihre Beschäftigten bereits vertraut?",
          description: "Mehrfachauswahl möglich",

          showNoneItem: true,
          noneText: "Keine KI-Tools",
          showOtherItem: true,
          otherText: "Sonstige KI-Tools",
          choices: [
            { value: 1, text: "Hardwareintegrierte KI-Tools (z. B. KI-Laptops inkl. Lizenzen)" },
            { value: 2, text: "Weit verbreitete, cloudbasierte KI-Tools wie OpenAI ChatGPT, Google Gemini oder Microsoft Copilot" },
            { value: 3, text: "KI-Funktionen allgemeiner Anwendungen (z. B. Copilot in Office 365)" },
            { value: 4, text: "KI-Funktionen spezifischer Anwendungen (z. B. eines CRMs)" },
          ],
        },
      ],
    },
    {
      name: "KI-Schulungen",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q6",
          title: "Welche KI-Schulungen werden Ihrer Einschätzung nach benötigt?",
          description: "Mehrfachauswahl möglich",

          showNoneItem: true,
          noneText: "Wir haben kein Interesse an Schulungen zum Thema KI bzw. benötigen keine.",
          choices: [
            { value: 1, text: "Schulungen zur effizienteren Zusammenarbeit als Team mit KI-Tools" },
            { value: 2, text: "Schulungen zur effizienteren Arbeit als Einzelperson mit KI-Tools" },
            { value: 3, text: "Sensibilisierungen für die Möglichkeiten von KI für den Arbeitsalltag " },
          ],
        },
        {
          type: "comment",
          name: "Q6-comment",
          visibleIf: "{Q6} contains 'none'",
          title: "Warum haben Sie kein Interesse bzw. warum benötigen Sie keine Schulungen zum Thema KI?",
          maxLength: 300,
        },
      ],
    },
    {
      name: "Early Adopter / Long-Term & Short-Term",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Q7",
          title: "Welche Rolle spielt KI in Ihren strategisch-technischen Entscheidungen?",

          choices: [
            { value: 1, text: "Wir wollen Marktführer unserer Branche hinsichtlich KI werden (Long-Term-Investments)" },
            { value: 2, text: "Wir wollen durch KI unsere Wettbewerbsfähigkeit steigern (Long-Term-Investments)" },
            { value: 3, text: "Wir wollen mit KI wettbewerbsfähig bleiben  (Short-Term-Investments)" },
            { value: 4, text: "Wir wollen den Anschluss nicht verlieren (Short-Term-Investments)" },
            { value: 5, text: "KI spielt in unserer strategisch-technischen Planung keine Rolle" },
          ],
        },
      ],
    },
    {
      name: "Experience der Beschäftigten",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q8",
          title: "Wie messen Sie die Erfahrungen Ihrer Beschäftigten mit ihrer Arbeitsausstattung?",
          description: "Mehrfachauswahl möglich",

          showNoneItem: true,
          noneText: "Wir messen die Erfahrung und Zufriedenheit unserer Beschäftigten nicht",

          choices: [
            {
              value: 1,
              text: "Wir befragen alle Beschäftigten in individuellen Gesprächen zu ihren Bedürfnissen und Erfahrungen hinsichtlich ihrer Arbeitsweise",
            },
            {
              value: 2,
              text: "Wir führen regelmäßig stichprobenartig 1-zu-1-Gespräche durch, um einen tiefen Einblick in das Arbeitsverhalten unserer Beschäftigten zu erhalten",
            },
            { value: 3, text: "Wir führen regelmäßige quantitative Umfragen durch, um einen allgemeinen Überblick zu bekommen" },
            { value: 4, text: "Wir reagieren auf proaktives Feedback unserer Belegschaft zur Verbesserung der Arbeitsprozesse" },
          ],
        },
      ],
    },
    {
      name: "Investition & Lizenzen",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q9",
          title: "Welche dieser Investitionen planen Sie in den nächsten 6 Monaten?",
          description: "Mehrfachauswahl möglich",

          showNoneItem: true,

          noneText: "Wir planen zeitnah keine Investitionen in KI",

          choices: [
            { value: 1, text: "Wir möchten unsere Hardware- und Lizenzausgaben durch KI-Laptops / KI-PCs miteinander kombinieren" },
            {
              value: 2,
              text: "Wir investieren zukünftig in Office-Anwendungen mit integrierten KI-Funktionen (z. B. Office 365 oder CRMs mit KI-Funktionen)",
            },
            { value: 3, text: "Wir investieren zukünftig in Lizenzen cloudbasierter KI-Lösungen (z. B. von ChatGPT)" },
            { value: 4, text: "Wir entwickeln zukünftig unsere eigene KI" },
          ],
        },
      ],
    },
    {
      name: "Produktivität",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q10",
          title: "Welche dieser Vorteile versprechen Sie sich persönlich von einer KI im Arbeitsalltag?",
          description: "Mehrfachauswahl möglich",

          showNoneItem: true,
          noneText: "Ich verspreche mir keine Zugewinne durch die Nutzung von KI in meinem Arbeitsalltag",
          showOtherItem: true,
          otherText: "Ich verspreche mir andere Zugewinne durch die Nutzung von KI in meinem Arbeitsalltag",
          choices: [
            { value: 1, text: "Zeitgewinn für eine bessere Work-Life-Balance" },
            {
              value: 2,
              text: "Zeitgewinn für die Entwicklung innovativer Geschäftsmodelle und Ideen (Business Development) sowie für kreative Prozesse",
            },
            { value: 3, text: "Technische Ermöglichung kreativer Prozesse (z. B. durch Generative AI)" },
            { value: 4, text: "Erleichterte Informationsgewinnung" },
          ],
        },
      ],
    },
    {
      name: "Collaboration",
      elements: [
        {
          type: "checkbox",
          isRequired: true,
          name: "Q11",
          title: "Welche Möglichkeiten bietet KI Ihrer Einschätzung nach für Ihre Team-interne und -externe Zusammenarbeit?",
          description: "Mehrfachauswahl möglich",
          showNoneItem: true,
          noneText: "Wir sehen keine Vorteile durch KI für unsere Zusammenarbeit",
          choices: [
            { value: 1, text: "Live-Transkription von Meetings (Speech-to-Text)" },
            { value: 2, text: "Zusammenfassung von E-Mails oder Dokumenten (Text-to-Text)" },
            {
              value: 3,
              text: "Kollaborationstools als Mittelpunkt (z. B. als zentraler Ausgangspunkt via Suche oder verknüpfter Daten in einer offenen Plattformarchitektur)",
            },
            { value: 4, text: "Zeitoptimierte Kalender- & Meeting-Planung" },
          ],
        },
      ],
    },
    {
      name: "Security",
      elements: [
        {
          type: "radiogroup",
          isRequired: true,
          name: "Q12",
          title: "Wie verarbeiten herkömmliche KI-Tools Ihrer Einschätzung nach die verwendeten Daten?",

          choices: [
            { value: 1, text: "Die Daten werden für das Modell verwendet und an Dritte weitergegeben" },
            { value: 2, text: "Die Daten werden ohne meine Einwilligung zur Verfeinerung des Modells verwendet und beim Betreiber gespeichert" },
            { value: 3, text: "Die Daten werden mit meiner Einwilligung zur Verfeinerung des Modells verwendet und beim Betreiber gespeichert" },
            { value: 4, text: "Die Daten werden nur für meine Anfrage verwendet, aber beim Betreiber gespeichert" },
            { value: 5, text: "Die Daten werden nur für meine Anfrage verwendet und bleiben sicher bei mir" },
          ],
        },
      ],
    },
  ],
};
