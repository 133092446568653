<template>
  <div v-show="showIfArray.some((element) => store.state.resultData.includes(element)) || showAlways" class="col-md-4">
    <div class="card border-secondary mb-3" style="min-height: 30rem">
      <div class="card-header">
        <span class="fa-stack fa-2x">
          <i class="fas fa-circle fa-stack-2x text-primary"></i>
          <i class="fas fa-stack-1x fa-inverse" :class="faIcon"></i>
        </span>
        <p class="my-3" style="min-height: 3rem">
          <b> {{ title }}</b>
        </p>
      </div>
      <div class="card-body text-start">
        <p class="card-text">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue";

// Store wird injiziert
const store = inject("store");

const props = defineProps({
  title: String,
  text: String,
  showIfArray: { type: Array, default: () => [] },
  showAlways: Boolean,
  faIcon: String,
});
</script>

<style scoped></style>
