<template>
  <header class="masthead">
    <div class="container masthead-content" v-show="modus === 'New'">
      <div class="row d-flex align-items-stretch">
        <div class="col-md-8 col-12 d-flex flex-column">
          <div class="masthead-subheading">Die neue KI-Ära ist da und revolutioniert die Arbeitswelt.</div>
          <div class="masthead-heading text-uppercase">Viele Unternehmen haben Ihre KI-Reise schon gestartet – wie steht es um Ihres?</div>

          <div class="card border-info mt-auto d-flex flex-column">
            <div class="card-body px-4">
              <h5 class="card-title mb-5">Der Selbsttest zeigt, wo Sie grade stehen.</h5>

              <p class="card-text text-start">
                Künstliche Intelligenz bietet eine Vielzahl von Möglichkeiten, Prozesse zu optimieren und so die Effizienz zu steigern. Sie schafft jedoch auch Freiräume für
                wichtigere und kreativere Aufgaben, die dem Unternehmen zusätzliche Mehrwerte generieren.
              </p>
              <p class="card-text text-start">
                Dieser Selbsttest zielt darauf ab, Ihnen einen Überblick über die Voraussetzungen und die Implementierung von KI-Technologien in Ihrem Unternehmen zu verschaffen.
                HP möchte Sie auf Ihrer KI-Reise unterstützen und Ihnen auf Basis Ihrer Antworten individuell zugeschnittene Empfehlungen für Weiterbildungsmöglichkeiten (z. B.
                Literatur und Workshops) präsentieren. Bitte nehmen Sie sich daher 5 Minuten Zeit, die Fragen sorgfältig zu beantworten, um ein genaues Bild davon zu erhalten, wie
                weit Ihr Unternehmen auf dem Weg zur Integration von KI ist und in welchen Bereichen noch Potenzial zur Weiterentwicklung besteht.
              </p>
            </div>

            <div class="card-body mt-auto">
              <button class="btn btn-primary btn-lg text-uppercase my-5" @click="startFreshSurvey">Assessment beginnen</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 d-flex flex-column">
          <div class="card border-primary bg-white mt-auto h-100">
            <div class="card-body">
              <h5 class="card-title">KI-Laptop von HP – die neue Ära der PCs</h5>
            </div>
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <img
                  class="img-fluid"
                  src="../../assets/img/Startseite/HP_EliteBook_Ultra_G1q_AI_PC_14_AtmosphericBlue_Catalog_AiGlass_WhiteBG_FrontLeft.jpg"
                  alt="HP EliteBook Ultra G1q KI-PC"
                />
              </div>
            </div>

            <div class="card-body text-start px-4">
              <p class="card-text">
                <small>
                  Mit dem „HP EliteBook Ultra G1q KI-PC“ startet HP in die KI-Ära der PCs. Die eingebaute NPU ist in der Lage, 45 TOPS auszuführen. Dadurch lassen sich
                  KI-Anwendungen lokal auf dem Rechner anwenden, statt sie in der Cloud verarbeiten zu müssen. Mit dem KI-PC mit Copilot können Sie geschäftskritische
                  Informationen schneller finden, Ideen in die Tat umsetzen, schneller auf Kundenwünsche reagieren und Ihre sensibelsten Daten schützen.</small
                >
              </p>
            </div>

            <div class="card-body mt-auto">
              <a href="https://www.hp.com/de-de/laptops/business/elitebooks/ultra-ai-pc.html" target="_blank" class="btn btn-outline-primary btn-lg text-uppercase"
                >Hier erfahren Sie mehr</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-show="modus !== 'New'">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <SurveyComponent :model="survey" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import "survey-core/defaultV2.min.css";
import { themeJson } from "../data/theme.js";
import { Model } from "survey-core";
import { surveyJson } from "../data/survey.js";
import "survey-core/survey.i18n";
import "survey-core/i18n/german";
import { getScore, getResultFilters } from "../data/evaluation.js";

import SectionLogos from "../components/SectionLogos.vue";

const survey = new Model(surveyJson);
survey.applyTheme(themeJson);
survey.locale = "de";
import { v4 as UUID, validate as isUuid } from "uuid";
const store = inject("store");

const modus = ref("New");

// Wenn bereits daten vorliegen und über xxx aufgerufen wurde
onMounted(() => {
  //Ist URL korrekt?
  const urlParts = window.location.href.split("/");
  if (urlParts[3] !== "survey" || !isUuid(urlParts[4])) {
    //console.log("falsche URL", urlParts);
    //console.log("urlParts[3]", urlParts[3]);
    //console.log("isUuid(urlParts[4]", isUuid(urlParts[4]));
  } else {
    //Modus wechseln und ID sichern
    modus.value = "Edit";
    const surveyId = urlParts[4];
    store.mutations.setSurveyId(surveyId);

    //Befragungsdaten abrufen
    axios
      .get(`/api/survey-results/${store.state.surveyId}`)
      .then((response) => {
        if (response.status === 200) {
          store.mutations.setSurveyData(JSON.parse(response.data.survey_data));
          store.mutations.setResultData(JSON.parse(response.data.result_data));
          store.mutations.setScore(response.data.score);

          survey.data = store.state.surveyData;

          survey.onValueChanged.add((sender, options) => {
            const el = document.getElementById(options.name);
            if (el) {
              el.value = options.value;
            }
          });
        } else {
        }
      })
      .catch((error) => {});
  }
});

function startFreshSurvey() {
  modus.value = "Started";
}

survey.onComplete.add(function (sender, options) {
  if (modus.value !== "Edit") {
    store.mutations.setSurveyId(UUID());
  }
  store.mutations.setSurveyData(sender.data);
  store.mutations.setResultData(getResultFilters(sender.data));
  store.mutations.setScore(getScore(store.state.resultData));

  const data = {
    survey_id: store.state.surveyId,
    survey_data: JSON.stringify(store.state.surveyData),
    result_data: JSON.stringify(store.state.resultData),
    score: store.state.score,
  };
  options.showSaveInProgress();
  axios
    .post("/api/survey-results", data)
    .then((response) => {
      if (response.status === 201) {
        options.showSaveSuccess();
        // options.clearSaveMessages();
        const currentDomain = window.location.origin;
        window.location.href = `${currentDomain}/result/${store.state.surveyId}`;
      } else {
        options.showSaveError(); // pass a string value to display a custom message
      }
    })
    .catch((error) => {
      options.showSaveError(); // pass a string value to display a custom message
    });
});
</script>
