<template>
  <section class="bsb-cta-1 px-2 bsb-overlay bsb-background-image">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
          <h2 class="fs-5 mb-3 text-white text-uppercase">Kontakt</h2>
          <h2 class="display-5 text-white mb-4">Hier finden Sie unser Kontaktformular, um individuelle Beratung zu bekommen</h2>
          <a href="https://www.hp.com/de-de/business/smb.html#services" target="_blank" class="btn bsb-btn-2xl btn-light rounded mb-0 text-nowrap">Besuchen Sie den HP SMB Hub</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script></script>

<style scoped>
.bsb-background-image {
  background-image: url("../../assets/img/kontakt/HP_KI_Assessment_Kontakt_SMB_Hub_Small_Medium_Business_compressed.png");
}
.bsb-overlay {
  --bsb-overlay-opacity: 0.5;
  --bsb-overlay-bg-color: var(--bs-black-rgb);
  position: relative;
}
.bsb-overlay:after {
  background-color: rgba(var(--bsb-overlay-bg-color), var(--bsb-overlay-opacity));
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.bsb-overlay > * {
  position: relative;
  z-index: 1;
}
.bsb-overlay-figure {
  --bsb-overlay-figure-opacity: 0.5;
  --bsb-overlay-figure-bg-color: var(--bs-black-rgb);
  position: relative;
}
.bsb-overlay-figure:after {
  background-color: rgba(var(--bsb-overlay-figure-bg-color), var(--bsb-overlay-figure-opacity));
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.bsb-overlay-hover {
  --bsb-overlay-hover-opacity: 0.5;
  --bsb-overlay-hover-bg-color: var(--bs-black-rgb);
  position: relative;
}
.bsb-overlay-hover > a {
  bottom: 0;
  display: block;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
}
.bsb-overlay-hover > a > img.bsb-scale {
  --bsb-scale: 1;
}
.bsb-overlay-hover > a > img.bsb-scale,
.bsb-overlay-hover > a > img.bsb-scale-up {
  transform: scale3d(var(--bsb-scale), var(--bsb-scale), var(--bsb-scale));
  transform-style: preserve-3d;
  transition: transform 0.5s;
}
.bsb-overlay-hover > a > img.bsb-scale-up {
  --bsb-scale: 1.2;
}
.bsb-overlay-hover > a:after {
  background-color: rgba(var(--bsb-overlay-hover-bg-color), var(--bsb-overlay-hover-opacity));
  content: "";
  cursor: pointer !important;
  display: block;
  z-index: 0;
}
.bsb-overlay-hover > a:after,
.bsb-overlay-hover > figcaption {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.bsb-overlay-hover > figcaption {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
}
.bsb-overlay-hover > figcaption > * {
  opacity: 0;
}
.bsb-overlay-hover:hover > a > img.bsb-hover-scale {
  --bsb-scale-hover: 1;
  transform: scale3d(var(--bsb-scale-hover), var(--bsb-scale-hover), var(--bsb-scale-hover));
}
.bsb-overlay-hover:hover > a > img.bsb-hover-scale-up {
  --bsb-scale-hover: 1.2;
  transform: scale3d(var(--bsb-scale-hover), var(--bsb-scale-hover), var(--bsb-scale-hover));
}
.bsb-overlay-hover:hover > a:after {
  opacity: 1;
  transition: opacity 0.15s linear;
}
.bsb-overlay-hover:hover > figcaption {
  opacity: 1;
  transition: opacity 0.15s linear 0.1s;
}
.bsb-overlay-hover:hover > figcaption > .bsb-hover-fadeIn {
  --bsb-animation-duration: 500ms;
  animation-duration: var(--bsb-animation-duration);
  animation-fill-mode: both;
  animation-name: bsb-fadeIn;
}
.bsb-overlay-hover:hover > figcaption > .bsb-hover-fadeInUp {
  --bsb-animation-duration: 500ms;
  animation-duration: var(--bsb-animation-duration);
  animation-fill-mode: both;
  animation-name: bsb-fadeInUp;
}
.bsb-overlay-hover:hover > figcaption > .bsb-hover-fadeInDown {
  --bsb-animation-duration: 500ms;
  animation-duration: var(--bsb-animation-duration);
  animation-fill-mode: both;
  animation-name: bsb-fadeInDown;
}
.bsb-overlay-hover:hover > figcaption > .bsb-hover-fadeInLeft {
  --bsb-animation-duration: 500ms;
  animation-duration: var(--bsb-animation-duration);
  animation-fill-mode: both;
  animation-name: bsb-fadeInLeft;
}
.bsb-overlay-hover:hover > figcaption > .bsb-hover-fadeInRight {
  --bsb-animation-duration: 500ms;
  animation-duration: var(--bsb-animation-duration);
  animation-fill-mode: both;
  animation-name: bsb-fadeInRight;
}
.bsb-overlay-hover:hover > figcaption > .bsb-hover-zoomIn {
  --bsb-animation-duration: 500ms;
  animation-duration: var(--bsb-animation-duration);
  animation-fill-mode: both;
  animation-name: bsb-zoomIn;
}
.bsb-btn-xl {
  --bs-btn-padding-y: 0.625rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: calc(1.26rem + 0.12vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .bsb-btn-xl {
    --bs-btn-font-size: 1.35rem;
  }
}
.bsb-btn-2xl {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: calc(1.27rem + 0.24vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .bsb-btn-2xl {
    --bs-btn-font-size: 1.45rem;
  }
}
.bsb-btn-3xl {
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-padding-x: 1.75rem;
  --bs-btn-font-size: calc(1.28rem + 0.36vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .bsb-btn-3xl {
    --bs-btn-font-size: 1.55rem;
  }
}
.bsb-btn-4xl {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 2rem;
  --bs-btn-font-size: calc(1.29rem + 0.48vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .bsb-btn-4xl {
    --bs-btn-font-size: 1.65rem;
  }
}
.bsb-btn-5xl {
  --bs-btn-padding-y: 1.125rem;
  --bs-btn-padding-x: 2.25rem;
  --bs-btn-font-size: calc(1.3rem + 0.6vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .bsb-btn-5xl {
    --bs-btn-font-size: 1.75rem;
  }
}
.bsb-cta-1 {
  background-attachment: fixed;
  background-position: 50%;
  background-size: cover;
  padding-bottom: 3rem;
  padding-top: 3rem;
}
@media (min-width: 768px) {
  .bsb-cta-1 {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }
}
@media (min-width: 1200px) {
  .bsb-cta-1 {
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
}
</style>
