//All Pages
import "./bootstrap";

import { createApp } from "vue";
const app = createApp({});

import { surveyPlugin } from "survey-vue3-ui";
app.use(surveyPlugin);

import store from "./store/store.js";
app.provide("store", store);

import Survey from "./pages/Survey.vue";
app.component("survey", Survey);

import Result from "./pages/Result.vue";
app.component("result", Result);

import Admin from "./pages/Admin.vue";
app.component("admin", Admin);

app.mount("#app");
