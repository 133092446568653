import { reactive } from "vue";

const state = reactive({
  surveyId: "", //uuid
  surveyData: [],
  resultData: [],
  score: 0,
});

const mutations = {
  setSurveyId(value) {
    state.surveyId = value;
  },
  setSurveyData(value) {
    state.surveyData = value;
  },
  setResultData(value) {
    state.resultData = value;
  },
  setScore(value) {
    state.score = value;
  },
};

export default { state, mutations };
