export const getResultFilters = function (surveyData) {
  //Befragungsdaten werden in die Form der Schlüssel wie "resultDictionary" gebracht und in "resultFilters" gesammelt.

  let resultFilters = [];

  //Schlüssel für Single-Fragen sammeln
  for (let i = 0; i < singles.length; i++) {
    let key = singles[i];
    if (surveyData.hasOwnProperty(key)) {
      resultFilters.push(key + "==" + surveyData[key]);
    }
  }

  /*  Beispiel für unten (Multiple): Q3 = [1,2,4,"other"]
   *  Ist "none" in surveyData["Q3"], dann ist der string "Q3quantity==none"
   *  Ist "other" in surveyData["Q3"] und length of surveyData["Q3"] ist 1, dann ist der string "Q3quantity==justOther"
   *  Ist "other" in surveyData["Q3"] und length of surveyData["Q3"] ist größer 1, dann ist der string "Q3quantity==" + length(surveyData["Q3"] minus 1)
   *  Ist "other"  nicht in surveyData["Q3"], dann ist der string "Q3quantity==" + length(surveyData["Q3"])
   */

  //Schlüssel für Multiple-Fragen sammeln
  for (let i = 0; i < multiples.length; i++) {
    let key = multiples[i];
    if (surveyData.hasOwnProperty(key)) {
      let answers = surveyData[key];
      let newString;

      if (answers.includes("none")) {
        newString = key + "quantity==none";
      } else if (answers.includes("other")) {
        if (answers.length === 1) {
          newString = key + "quantity==justOther";
        } else {
          newString = key + "quantity==" + (answers.length - 1);
        }
      } else {
        newString = key + "quantity==" + answers.length;
      }
      resultFilters.push(newString);
    }
  }
  return resultFilters;
};

export const getScore = function (resultFilters) {
  //Die Schlüssel in "resultDictionary" nachschauen und die erreichten Punkte aufsummieren
  let sum = 0;

  for (let i = 0; i < resultFilters.length; i++) {
    let key = resultFilters[i];
    if (resultDictionary.hasOwnProperty(key)) {
      sum += resultDictionary[key];
    }
  }
  //Normieren auf 0.0 bis 4.0 Punkte
  sum = sum / 12.0;
  return sum;
};

let singles = ["Q1", "Q2", "Q4", "Q7", "Q12"];

let multiples = ["Q3", "Q5", "Q6", "Q8", "Q9", "Q10", "Q11"];

let resultDictionary = {
  "Q1==1": 4,
  "Q1==2": 3,
  "Q1==3": 2,
  "Q1==4": 1,
  "Q1==5": 0,
  "Q2==1": 4,
  "Q2==2": 3,
  "Q2==3": 2,
  "Q2==4": 2,
  "Q2==5": 2,
  "Q2==6": 2,
  "Q3quantity==5": 4,
  "Q3quantity==4": 3,
  "Q3quantity==3": 2,
  "Q3quantity==2": 2,
  "Q3quantity==1": 1,
  "Q3quantity==justOther": 1,
  "Q3quantity==none": 0,
  "Q4==1": 4,
  "Q4==2": 3,
  "Q4==3": 2,
  "Q4==4": 1,
  "Q4==5": 0,
  "Q5quantity==4": 4,
  "Q5quantity==3": 3,
  "Q5quantity==2": 2,
  "Q5quantity==1": 1,
  "Q5quantity==justOther": 1,
  "Q5quantity==none": 0,
  "Q6quantity==3": 3,
  "Q6quantity==2": 2,
  "Q6quantity==1": 1,
  "Q6quantity==none": 0,
  "Q7==1": 4,
  "Q7==2": 3,
  "Q7==3": 2,
  "Q7==4": 1,
  "Q7==5": 0,
  "Q8quantity==4": 4,
  "Q8quantity==3": 3,
  "Q8quantity==2": 2,
  "Q8quantity==1": 1,
  "Q8quantity==none": 0,
  "Q9quantity==4": 4,
  "Q9quantity==3": 3,
  "Q9quantity==2": 2,
  "Q9quantity==1": 1,
  "Q9quantity==none": 0,
  "Q10quantity==4": 4,
  "Q10quantity==3": 3,
  "Q10quantity==2": 2,
  "Q10quantity==1": 1,
  "Q10quantity==justOther": 1,
  "Q10quantity==none": 0,
  "Q11quantity==4": 4,
  "Q11quantity==3": 3,
  "Q11quantity==2": 2,
  "Q11quantity==1": 1,
  "Q11quantity==none": 0,
  "Q12==1": 4,
  "Q12==2": 3,
  "Q12==3": 2,
  "Q12==4": 1,
  "Q12==5": 0,
};
