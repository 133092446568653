<template>
  <header class="masthead">
    <div class="container masthead-content">
      <div class="masthead-subheading">KI-Check: Ihre persönliche Auswertung</div>
      <div v-show="store.state.score >= 3.0" class="masthead-heading text-uppercase">Ein solides Fundament ermöglicht es Ihnen, die richtigen Stellschrauben zu drehen</div>
      <div v-show="store.state.score >= 3.0" class="masthead-subheading">
        Sie haben es bereits weit geschafft. Sie sind mit KI-Wissen und Voraussetzungen ausgestattet, auf denen sich weitreichende Möglichkeiten zur Produktivitätssteigerung
        aufbauen lassen. Das Themenfeld KI entwickelt sich jedoch rasant weiter. Was heute noch "state of the art" war, kann morgen bereits ein alter Hut sein. HP möchte sie dabei
        unterstützen, Ihren Wissensstand auszubauen und so zielgerichtet wie möglich einzusetzen. Im Folgenden finden Sie daher einige speziell auf Ihre Bedürfnisse zugeschnittene
        Materialien und Weiterbildungsmöglichkeiten.
      </div>
      <div v-show="store.state.score >= 2.0 && store.state.score < 3.0" class="masthead-heading text-uppercase">
        Erste Schritte sind getan, <br />doch hier endet die Reise noch lange nicht
      </div>
      <div v-show="store.state.score >= 2.0 && store.state.score < 3.0" class="masthead-subheading">
        Sie haben bereits bedeutende Fortschritte auf dem Weg zu einer KI-gestützten Arbeitsumgebung erzielt. Die wichtigsten Grundlagen sind erfolgreich geschaffen worden, und Sie
        sind auf einem vielversprechenden Weg hin zu erhöhter Produktivität, Sicherheit und verbesserter Zusammenarbeit mit KI. HP möchte Sie dabei unterstützen, Ihr Wissen weiter
        zu vertiefen und Ihre individuelle KI-Reise gemeinsam erfolgreich zu gestalten. Im Folgenden finden Sie eine Auswahl speziell auf Ihre Bedürfnisse zugeschnittener
        Materialien und Weiterbildungsmöglichkeiten.
      </div>
      <div v-show="store.state.score < 2.0" class="masthead-heading text-uppercase">Am Anfang einer spannenden Reise</div>
      <div v-show="store.state.score < 2.0" class="masthead-subheading">
        Ihre KI-Reise hat gerade erst begonnen. Vor allem Bedenken und Unsicherheiten halten Sie derzeit von ersten Kontaktaufnahmen mit KI-Tools ab. Viele Baustellen an
        unterschiedlichen Orten können oft überwältigend wirken. HP möchte Sie dabei unterstützen, grundlegende Hemmnisse aus dem Weg zu räumen, und gemeinsam mit Ihnen Ihre
        individuelle KI-Reise bewältigen. Im Folgenden finden Sie daher einige speziell auf Ihre Bedürfnisse zugeschnittene Materialien und Weiterbildungsmöglichkeiten.
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-1 d-none d-lg-block"></div>
          <div class="col-12 col-md-6 col-lg-4 mb-3 mb-md-0">
            <div class="d-grid gap-2">
              <button @click="copyToClipboard" type="button" class="btn btn-light btn-lg w-100 bubble-btn">Permalink kopieren</button>
              <p><small>Kopieren Sie sich den Permalink zu dieser Seite in die Zwischenablage, um sie später erneut aufrufen zu können.</small></p>
            </div>
          </div>
          <div class="col-lg-2 d-none d-lg-block"></div>
          <div class="col-12 col-md-6 col-lg-4 mb-3 mb-md-0">
            <div class="d-grid gap-2"><a type="button" class="btn btn-info btn-lg w-100" :href="surveyLink">Befragungsdaten ändern</a></div>
          </div>
          <div class="col-lg-1 d-none d-lg-block"></div>
        </div>
      </div>
    </div>
  </header>
  <pre v-if="false">
    {{ store.state.surveyData }}

</pre
  >
  <section class="page-section" id="services">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Wichtige Erkenntnisse Ihrer Auswertung</h2>
      </div>
      <div class="row text-center">
        <IconCard
          title="Aktualisieren Sie Ihre Office-Hardware für Sicherheit und Effizienz"
          text="Ihre Office-Hardware sollte zeitnah einer Aktualisierung unterzogen werden. Moderne Systeme legen den Grundstein für den reibungslosen Betrieb essenzieller Software.
            Alte Systeme sind oft inkompatibel mit modernen Anwendungen, zu schwach für sie oder anfälliger für Sicherheitslücken und Cyberangriffe."
          :showIfArray="['Q1==3', 'Q1==4', 'Q1==5']"
          faIcon="fa-microchip"
        />

        <IconCard
          title="Sichern Sie Ihre Systeme: Wechseln Sie zu Windows 11"
          text="Da Microsoft den Support für Windows 10 Ende 2025 beendet, riskieren Sie ohne den Umstieg auf Windows 11 gravierende Sicherheitslücken in Ihrer Flotte. Nicht nur das:
            Zukünftige Anwendungen werden Windows 11 als Bedingung für einen stabilen Betrieb voraussetzen."
          :showIfArray="['Q2==2']"
          faIcon="fa-computer"
        />

        <IconCard
          title="Schützen Sie Ihr Unternehmen: Verlassen Sie Windows 7 und 10"
          text=" Bereits seit Anfang 2020 gibt es keine weiteren (Sicherheits-)Updates für Windows 7 und sogar Windows 10 wird dieses Schicksal zeitnah ereilen. Alte Anwendungen, die
            noch auf Windows 7 basieren, halten sie möglicherweise zurück. Sie riskieren hierdurch die Wettbewerbsfähigkeit Ihres Unternehmens."
          :showIfArray="['Q2==3']"
          faIcon="fa-computer"
        />

        <IconCard
          title="Fördern Sie KI-Offenheit: Gehen Sie mit gutem Beispiel voran"
          text="Beschäftigte werden in der nahen Zukunft nicht um die Nutzung von KI-Tools herumkommen.
          Unsicherheiten oder Sicherheitsbedenken sind hierbei keine Seltenheit. Nehmen Sie Ihrem Team die Angst und gehen Sie mit gutem Beispiel voran: Durch gezielte und zugeschnittene Aufklärung können Hemmungen abgebaut und der Weg zur erfolgreichen Integration von KI-Tools im Team geebnet werden."
          :showIfArray="['Q4==3', 'Q4==4', 'Q4==5']"
          faIcon="fa-users"
        />

        <IconCard
          title="Erkunden Sie KI-Tools: Treffen Sie fundierte Entscheidungen"
          text="Wenn über den Tellerrand hinausgeschaut wird, bietet KI unzählige Integrationsmöglichkeiten, von einer eigenständigen, generativen Lösung über einzelne Funktionalitäten
            bis hin zu einer komplexen Verschachtelung vorhandener Daten. Die Nutzung der erstbesten Möglichkeit kann auf lange Sicht die Wettbewerbsfähigkeit kosten. Setzen Sie
            sich mit allen Ebenen eines KI-Tools auseinander (z. B. über Workshops oder Studien) und entscheiden Sie dann über den bestmöglichen Fit für Ihr Unternehmen."
          :showIfArray="['Q5quantity==3', 'Q5quantity==2', 'Q5quantity==1', 'Q5quantity==justOther', 'Q5quantity==none']"
          faIcon="fa-lightbulb"
        />

        <IconCard
          title="Nutzen Sie KI für nachhaltigen Erfolg"
          text="Die Bedeutung von KI für die Wettbewerbsfähigkeit ist enorm. Viele Unternehmen stehen vor der Herausforderung, KI nachhaltig in ihre Strategie zu integrieren, sind Sie also nicht allein: Mit kompetenter Beratung werden Sie erfolgreich Ihren KI-Weg zu gesteigerter Wettbewerbsfähigkeit finden."
          :showIfArray="['Q7==3', 'Q7==4', 'Q7==5']"
          faIcon="fa-chart-line"
        />

        <IconCard
          title="Berücksichtigen Sie individuelle Mitarbeiterbedürfnisse"
          text="Ein allgemeiner Überblick über die Erfahrungen und Bedürfnisse der Beschäftigten ist ein guter Start in Richtung einer Mitarbeiter-zentrierten Arbeitsplatzausstattung.
           Unterschiedliche Beschäftigte haben jedoch sehr individuelle Anforderungen, die sich auf diese Weise nicht
            erfassen lassen. Hierfür ist ein individueller Ansatz notwendig, der sich z. B. in Form von Personas mit den allgemeinen Informationen kombinieren lässt."
          :showIfArray="['Q8quantity==2']"
          faIcon="fa-user"
        />

        <IconCard
          title="Optimieren Sie Arbeitsplätze für höhere Produktivität"
          text="Die Einschätzungen und Bedürfnisse der Beschäftigten an die Ausstattung eines Arbeitsplatzes haben einen immensen Einfluss auf ihre Zufriedenheit und daher auch auf
            ihre Produktivität. Schlanke, zugeschnittene Hard- und Software, die sich sowohl an allgemeinen als auch individuellen Aspekten orientiert, öffnet die Tür für
            zahlreiche Produktivitätssprünge. Weiter unten finden Sie eine Auswahl an Produkten/Hardware, die basierend auf Ihren Antworten passen könnten"
          :showIfArray="['Q8quantity==1', 'Q8quantity==none']"
          faIcon="fa-user"
        />

        <IconCard
          title="Erleichtern Sie Investitionen durch integrierte KI-Lösungen"
          text="Eine KI bietet unzählige Integrationsmöglichkeiten, bringt jedoch auch Herausforderungen durch Fachkräftemangel und Wissensdefizite mit sich. Eine bereits integrierte KI kann viel Entwicklungszeit einsparen und das Lizenzmanagement vereinfachen. Verpassen Sie nicht die Chance und planen Sie frühzeitig Investitionen in integrierte KI-Lösungen, um wettbewerbsfähig zu bleiben."
          :showIfArray="['Q9quantity==2', 'Q9quantity==1', 'Q9quantity==none']"
          faIcon="fa-money-bill-trend-up"
        />

        <IconCard
          title="Priorisieren Sie Sicherheit bei der KI-Nutzung"
          text="KI-Tools (vor allem aus den USA) nutzen Ihre Daten (oft auch ohne explizite Einwilligung) zur Weiterentwicklung ihrer KI-Modelle und speichern Ihre Daten in den eigenen
            Rechenzentren bzw. in der Cloud. Es ist daher ratsam, die Security- sowie Compliance-Ansätze der KI-Unternehmen genau zu analysieren und Alternativen mit höheren Sicherheitsstandards in Betracht zu nehmen. KI-PCs speichern Ihre Daten beispielsweise ausschließlich auf Ihrem lokalen Gerät und lassen deren Verarbeitung
            auch nur dort zu."
          :showIfArray="['Q12==3', 'Q12==4', 'Q12==5']"
          faIcon="fa-shield"
        />
      </div>
    </div>
  </section>

  <section class="page-section pb-5" id="portfolio">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Unsere Leseempfehlung <br />mit hilfreichen Guidelines und Hilfestellungen</h2>
        <h3 class="section-subheading">Folgende Fachartikel und Unterlagen könnten Sie auf Basis Ihrer Antworten interessieren:</h3>
      </div>
      <div class="list-group">
        <ArticleListItem
          class="bg-primary"
          type="Collection"
          title="Heise Audience Hub - Rubrik KI"
          text="Gewinnen Sie einen Überblick über Chancen & Möglichkeiten der KI im Arbeitsalltag"
          show-always
          url="https://it-kenner.heise.de/hybrid-work/vorsprung-mit-ki/"
        />

        <ArticleListItem
          type="Fachartikel"
          class="bg-success"
          title="Warum KI die Cybersicherheit erhöht"
          text="KI in der IT-Sicherheit"
          :showIfArray="['Q12==2', 'Q12==3', 'Q12==4', 'Q12==5']"
          url="https://it-kenner.heise.de/hybrid-work/vorsprung-mit-ki/warum-ki-die-cybersicherheit-erhoeht/"
        />

        <ArticleListItem
          type="Fachartikel"
          class="bg-success"
          title="Hybrid Work holt KI an den Arbeitsplatz"
          text="KI für eine effiziente Zusammenarbeit"
          :showIfArray="['Q11quantity==3', 'Q11quantity==2', 'Q11quantity==1', 'Q11quantity==none']"
          url="https://it-kenner.heise.de/hybrid-work/anyhow/hybrid-work-holt-ki-an-den-arbeitsplatz/"
        />

        <ArticleListItem
          type="Fachartikel"
          class="bg-success"
          title="Meetingräume für den Mittelstand – modernes Arbeiten mit smarten Lösungen und KI"
          text="KI für eine effiziente Zusammenarbeit"
          :showIfArray="['Q11quantity==3', 'Q11quantity==2', 'Q11quantity==1', 'Q11quantity==none']"
          url="https://it-kenner.heise.de/hybrid-work/anywhere/meetingraeume-fuer-den-mittelstand-smarte-loesungen-fuer-modernes-arbeiten/"
        />

        <ArticleListItem
          type="Fachartikel"
          class="bg-success"
          title="KI als Booster im Hybrid-Work-Umfeld: Aufgaben schneller erledigen und Mitarbeiter entlasten"
          text="KI zur Produktivitätssteigerung"
          :showIfArray="['Q10quantity==3', 'Q10quantity==2', 'Q10quantity==1', 'Q10quantity==justOther', 'Q10quantity==none']"
          url="https://it-kenner.heise.de/hybrid-work/anytime/ki-als-booster-im-hybrid-work-umfeld-mitarbeiter-unterstuetzen-ihr-volles-potenzial-zu-nutzen/"
        />

        <ArticleListItem
          type="Fachartikel"
          class="bg-success"
          title="Warum KI für die digitale Transformation elementar ist und neue Chancen bietet"
          text="Digitale Transformation & Use Cases"
          :showIfArray="['Q4==2', 'Q4==3', 'Q4==4', 'Q4==5']"
          url="https://it-kenner.heise.de/hybrid-work/anyhow/wie-ki-die-digitale-transformation-vorantreibt/"
        />

        <ArticleListItem
          class="bg-primary"
          type="Collection"
          title="Konkrete Anwendungsfelder für KI"
          text="AI-Powered Experimentation Fueled By Extreme Compute Power"
          show-always
          url="https://h20195.www2.hp.com/v2/GetDocument.aspx?docname=4AA8-3683ENUS&jumpid=va_us_mu_mk_hc_r12139_aw_x_10306"
        />

        <ArticleListItem
          type="Use Case"
          class="bg-warning"
          title="Use Case: DB Fernverkehr"
          text="Daniel Springmann ist Ambassador für HP"
          show-always
          url="https://www.hp.com/us-en/workstations/industries/data-science/ambassador-daniel-springmann.html"
        />
      </div>
    </div>
  </section>

  <section class="page-section bg-dark py-0" id="portfolio">
    <SectionCTA></SectionCTA>
    <div class="container">
      <div v-if="false" class="text-center">
        <h2 class="section-heading text-uppercase text-bg-dark">Workshops</h2>
        <h3 class="section-subheading text-bg-dark">Als Hilfe zur Selbsthilfe werden hier passende Workshops vorgestellt</h3>
        <p class="section-subheading text-bg-dark">
          Wir möchten Sie dabei unterstützen, Ihre KI-Reise zu vereinfachen, und gemeinsam mit Ihnen Hindernisse aus dem Weg räumen. Wir planen ausgewählte Wissensformate wie
          Workshops, Roundtable oder Webinare, die speziell auf Ihre Needs zugeschnitten sind. Lassen Sie uns wissen, bei welchen Themen wir zukünftig mit einem Workshop auf Sie
          zukommen dürfen.
        </p>
      </div>
      <div v-if="false" class="row">
        <ImageCard text="Workshop mit HP" title="Konkrete Anwendungsfelder für KI" :showIfArray="['Q4==2']" :cols="4" url="#">
          <img class="img-fluid" src="../../assets/img/workshops/HP_KI_Workshop_Konkrete_Einsatzfelder_fuer_KI.png" alt="Konkrete Anwendungsfelder für KI" />
        </ImageCard>

        <ImageCard
          text="Workshop mit HP"
          title="Allgemeine Chancen und Risiken von KI-Tools"
          v-show="
            (store.state.surveyData['Q6'] && store.state.surveyData['Q6'].includes(4)) || ['Q4==3', 'Q4==4', 'Q4==5'].some((element) => store.state.resultData.includes(element))
          "
          :cols="4"
          url="#"
        >
          <img
            class="img-fluid"
            src="../../assets/img/workshops/HP_KI_Workshop_Allgemeine_Chancen_und_Risiken_von_KI-Tools.png"
            alt="Allgemeine Chancen und Risiken von KI-Tools"
          />
        </ImageCard>

        <ImageCard
          text="Workshop mit HP"
          title="Möglichkeiten der KI für eine effiziente und individualisierte Zusammenarbeit"
          v-show="store.state.surveyData['Q6'] && store.state.surveyData['Q6'].includes(2)"
          :showIfArray="['Q11quantity==3', 'Q11quantity==2', 'Q11quantity==1', 'Q11quantity==none']"
          :cols="4"
          url="#"
        >
          <img
            class="img-fluid"
            src="../../assets/img/workshops/HP_ KI_Workshop_Möglichkeiten_der_KI_fuer_eine_effiziente_und_individualisierte_Zusammenarbeit.png"
            alt="Möglichkeiten der KI für eine effiziente und individualisierte Zusammenarbeit"
          />
        </ImageCard>

        <ImageCard text="Workshop mit HP" title="Effiziente KI-Nutzung" v-show="store.state.surveyData['Q6'] && store.state.surveyData['Q6'].includes(3)" :cols="4" url="#">
          <img class="img-fluid" src="../../assets/img/workshops/HP_KI_Workshop_Effiziente_KI-Nutzung.png" alt="Effiziente KI-Nutzung" />
        </ImageCard>

        <ImageCard text="Workshop mit HP" title="Datensicherheit bei KI-Tools" :showIfArray="['Q12==3', 'Q12==4', 'Q12==5']" :cols="4" url="#">
          <img class="img-fluid" src="../../assets/img/workshops/HP_KI_Workshop_Datensicherheit_bei_KI-Tools.png" alt="Datensicherheit bei KI-Tools" />
        </ImageCard>
      </div>

      <div v-if="false" class="row">
        <ImageCard title="Roundtable" text="Roundtable / Workshop in kleiner Runde" show-always :cols="3" url="#">
          <img class="img-fluid" src="../../assets/img/600x450.png" alt="Roundtable / Workshop in kleiner Runde" />
        </ImageCard>

        <ImageCard title="Webcast" text="Webcast von HP x heise" show-always :cols="3" url="#">
          <img class="img-fluid" src="../../assets/img/600x450.png" alt="Webcast von HP x heise" />
        </ImageCard>

        <ImageCard title="Live Chat" text="Live Chat von HP x heise" show-always :cols="3" url="#">
          <img class="img-fluid" src="../../assets/img/600x450.png" alt="Live Chat von HP x heise" />
        </ImageCard>

        <ImageCard title="Podcast" text="Podcast HP x heise" show-always :cols="3" url="#">
          <img class="img-fluid" src="../../assets/img/600x450.png" alt="Podcast HP x heise" />
        </ImageCard>
      </div>
    </div>
  </section>

  <section class="page-section bg-light">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Unsere Produkt-Empfehlung für Ihr Unternehmen - diese Ausstattung bringt Sie auf Ihrer KI-Reise voran</h2>
        <h3 class="section-subheading text-muted">
          Eine individuell auf Sie und Ihre Bedürfnisse - als Unternehmen und als Einzelperson - zugeschnittene Hardware fördert eine möglichst effiziente Arbeitsweise. Passgenaue
          Peripherie, Hardware und Software unterstützen Sie im Arbeitsalltag deutlich zielführender als die erstbeste Wahl. Auf Basis Ihrer Antworten können wir folgende Hardware
          besonders empfehlen:
        </h3>
      </div>
      <div class="row">
        <ImageCard
          title="Wieso? Weshalb? Warum?"
          text="Informationen zum Umstieg auf Windows 11"
          :showIfArray="['Q2==2', 'Q2==3']"
          :cols="4"
          url="https://www.hp.com/de-de/software/microsoft-windows-11-business-computers.html"
        >
          <img class="img-fluid" src="../../assets/img/Portfolio/Win%2011%20Seite/Windows11_refresh_site.jpg" alt="Windows 11 Refresh-Seite" />
        </ImageCard>

        <ImageCard title="Warum auf Windows 11 updaten?" text="Englisches Video" :showIfArray="['Q2==2', 'Q2==3']" :cols="4" url="https://www.youtube.com/watch?v=OThFKwMHlSY">
          <img class="img-fluid" src="../../assets/img/Portfolio/Win%2011%20Video/Windows11_refresh_clip_english.jpg" alt="Windows 11 Refresh-Clip" />
        </ImageCard>

        <ImageCard
          title="HP EliteBook Ultra G1q KI-PC"
          text="Mit eingebauter NPU und Copilot+ für leistungsstarke Performance und einzigartige KI-Erfahrungen"
          :showIfArray="['Q4==1', 'Q4==2']"
          :cols="4"
          url="https://www.hp.com/de-de/laptops/business/elitebooks/ultra-ai-pc.html"
        >
          <img class="img-fluid" style="background-color: gray" src="../../assets/img/Portfolio/EliteBook%20Ultra/HP_EliteBook_Ultra.png" alt="HP EliteBook Ultra G1q KI-PC" />
        </ImageCard>

        <ImageCard
          title="HP Elite 1040 G11er Series"
          text="Mit KI-Einbindung, leistungsstarken Intel Core Ultra 5 und 7 Prozessoren und 32GB RAM"
          v-if="false"
          :showIfArray="['Q4==3', 'Q4==4', 'Q4==5']"
          :cols="4"
          url="https://www.hp.com/de-de/laptops/business/elitebooks/1000-series.html"
        >
          <img
            class="img-fluid"
            src="../../assets/img/Portfolio/HP_Elite_x360_1040_14_inch_G11_2_in_1_GlacierSilver_T_IRcam_FPR_Win11_CoreSet_VisID_GrayBG_Tent__mit_screenshot_compressed.png"
            alt="HP Elite 1040 G11er Series"
          />
        </ImageCard>

        <ImageCard
          title="Konferenztechnik & -Ausstattung"
          text="Ergonomisch, passgenau und auf Ihre Bedürfnisse abgestimmt"
          show-always
          :cols="4"
          url="https://www.hp.com/de-de/poly.html?jumpid=va_ww_mu_mk_hc_r12129_aw_x_10957"
        >
          <img class="img-fluid" src="../../assets/img/Portfolio/Peripherie/Konferenztechnik-600x450.png" alt="Konferenztechnik & -Ausstattung" />
        </ImageCard>

        <ImageCard
          title="Secure Hardware: The Wolf"
          text="Sichere Laptops und Desktops auf jeder Ebene"
          show-always
          :cols="4"
          url="https://www.hp.com/de-de/security/computers-and-printers-hardware.html"
        >
          <img class="img-fluid" src="../../assets/img/Portfolio/The%20Wolf/HP_KI_Security_Hardware_The_Wolf.png" alt="Security für KI / The Wolf" />
        </ImageCard>
      </div>
    </div>
  </section>
  <SectionContactCTA></SectionContactCTA>
</template>

<script setup>
// http://localhost:8001/result/e1c09e43-f63a-4421-8de6-28c3ca2670f6
import SectionCTA from "../components/SectionCTA.vue";
import { inject, onMounted, ref, computed } from "vue";
import ImageCard from "../components/ImageCard.vue";
import IconCard from "../components/IconCard.vue";

import { validate as isUuid } from "uuid";
import ArticleListItem from "../components/ArticleListItem.vue";
import SectionLogos from "../components/SectionLogos.vue";
import SectionContactCTA from "@/components/SectionContactCTA.vue";
const store = inject("store");
const loaded = ref(false);

const surveyLink = computed(() => {
  const currentDomain = window.location.origin;
  return `${currentDomain}/survey/${store.state.surveyId}`;
});
const copyToClipboard = () => {
  const currentURL = window.location.href;
  navigator.clipboard
    .writeText(currentURL)
    .then(() => {
      alert("Permalink zu dieser Auswertung wurde in die Zwischenablage kopiert!");
    })
    .catch((err) => {
      console.error("Fehler beim Kopieren des Permalinks: ", err);
    });
};
onMounted(() => {
  const pathSegments = window.location.pathname.split("/");
  const idFromPath = pathSegments[pathSegments.length - 1];
  if (isUuid(idFromPath)) {
    store.mutations.setSurveyId(idFromPath);
  } else {
    //Fehler abfangen
  }

  axios
    .get(`/api/survey-results/${store.state.surveyId}`)
    .then((response) => {
      if (response.status === 200) {
        store.mutations.setSurveyData(JSON.parse(response.data.survey_data));
        store.mutations.setResultData(JSON.parse(response.data.result_data));
        store.mutations.setScore(response.data.score);
      } else {
      }
    })
    .catch((error) => {});
  loaded.value = true;
});
</script>

<style lang="scss" scoped></style>
